export function setActiveCategoryItem() {
    var currentPath = window.location.pathname;

    console.log(currentPath);

    var categoryItems = document.querySelectorAll('a.categoryItem');

    categoryItems.forEach(function(item) {
        var href = item.getAttribute('href');

        if (href === currentPath) {
            item.classList.add('active');
        }
    });
}