export function removeOtherHoverintents(callbackLeave = () => {
}) {
    var otherHoverintents = document.querySelectorAll(".hoverintent");

    for (let hover of otherHoverintents) {
        hover.classList.remove('hoverintent');
        callbackLeave();
    }
}

function hoverintent(el, duration = 200, callbackEnter = () => {
}, callbackLeave = () => {
}) {
    let timeOut;

    el.addEventListener("mouseenter", function () {
        clearTimeout(timeOut);
        timeOut = setTimeout(function () {
            el.classList.add('hoverintent');
            callbackEnter();
        }, duration);
    }, false);

    el.addEventListener("mouseleave", function () {
        clearTimeout(timeOut);
        timeOut = setTimeout(function () {
            el.classList.remove('hoverintent');
            callbackLeave();
            clearTimeout(timeOut);
        }, duration);
    }, false);

    el.addEventListener("touchstart", function () {
        clearTimeout(timeOut);
        removeOtherHoverintents(callbackLeave);
        el.classList.add('hoverintent');
    }, {passive: true});
}

export default hoverintent;