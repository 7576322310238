import Swiper, {Autoplay, Navigation} from 'swiper';
import {getOptionsFromJsonString} from "./utilities/options";

export function initScrollbars(query = document.querySelectorAll('.scrollbar')) {
    for (let scrollbar of query) {
        let options = getOptionsFromJsonString(scrollbar.dataset.options, {
            autoplay_interval: 5000,
            eff_interval: 300
        });
        let childrenCount = scrollbar.querySelectorAll('.swiper-wrapper > *').length;
        let loop = false;

        let breakpoints = {
            xs: {
                items: 4,
                autoplay: childrenCount > 4,
            },
            sm: {
                items: 4,
                autoplay: childrenCount > 4,
            },
            md: {
                items: 5,
                autoplay: childrenCount > 5,
            }
        }

        let gridBreakpoints = {};

        gridBreakpoints.sm = 768;
        gridBreakpoints.md = 1024;

        if (typeof GLOBALS!== "undefined") {
            gridBreakpoints.sm = GLOBALS.gridBreakpoints.sm;
            gridBreakpoints.md = GLOBALS.gridBreakpoints.md;
        }

        if (window.outerWidth > gridBreakpoints.sm && childrenCount > breakpoints.sm.items) {
            loop = true;
        }

        if (window.outerWidth > gridBreakpoints.md && childrenCount > breakpoints.md.items) {
            loop = true;
        }

        Swiper.use([Autoplay, Navigation]);
        new Swiper(scrollbar, {
            loop: loop,
            speed: options.eff_interval,
            centeredSlides: true,
            initialSlide: 1,
            spaceBetween: 10,
            autoplay: {
                delay: options.autoplay_interval,
            },
            navigation: {
                nextEl: scrollbar.querySelector('.swiper-button-next'),
                prevEl: scrollbar.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                0: {
                    slidesPerView: breakpoints.xs.items,
                    autoplay: {
                        enabled: breakpoints.xs.autoplay
                    }
                },
                768: {
                    slidesPerView: breakpoints.sm.items,
                    autoplay: {
                        enabled: breakpoints.sm.autoplay
                    }
                },
                1024: {
                    slidesPerView: breakpoints.md.items,
                    autoplay: {
                        enabled: breakpoints.md.autoplay
                    }
                }
            }
        });
    }
}
